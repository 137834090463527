
.split-btn {
  @include clearfix;
  display: inline-block;
  position: relative;
  padding-right: rem-calc(40);
  > .btn {
    float: left;
  }
  > .btn:first-child:not(:last-child):not(.split-btn_btn) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  > .split-btn_btn {

  }
}
.split-btn_btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: rem-calc(10px);
  position: absolute;
  right: 0;
  top: 0;
  &:active {
    transform: none;
  }
  &:after {
    font-family: 'Glyphicons Regular';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    vertical-align:top;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: middle;
    content:"\E602";
    display: inline-block;
    min-width: 1.3rem;
  }
  .btn--primary + & {
    background-color: $baseGreen;
    border-left: 1px solid $white;
    &:hover,
    &:focus {
      background-color: $greenDark;
    }
  }
}
.split-btn_dropdown {
  display: none;
  clear: left;
  position: absolute;
  top: 2.55rem;
  min-width: 180px;
  right: 0;
  z-index: 1;
  > .btn {
    display: block;
    width: 100%;
  }
}

.split-btn_btn:hover + .split-btn_dropdown,
.split-btn_dropdown:hover {
  display: block;
}

