
.error-message {
  color: $red;
  font-weight: bold;
  .widget & {
    margin-bottom: .25rem;
  }
  label + & {
    margin-top: 0;
  }


}


.error-message--success {
  color: $baseGreen;
}

.error-message--info {
  color: $baseGrey;
}

.error-message--warning {
  color: $orange;
}

.error-message--danger {
  color: $red;
}
