$susy: (
  columns: 12,
  gutters: 1/4,
  math: fluid,
  output: float,
  gutter-position: inside,
);

$breakpoints: (
  // Extra small screen / phone
        xs: 0,
  // Small screen / phone
        sm: 34em,
  // Medium screen / tablet
        md: 48em,
  // Large screen / desktop
        lg: 62em,
  // Extra large screen / wide desktop
        xl: 75em
);

$defaultTransition: all 0.2s ease-in-out;
$defaultTransitionSettings: 0.2s ease-in-out;
