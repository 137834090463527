.filter {
  @include media-breakpoint-up("md") {
    display: flex;
  }
  > .widget {
    margin-right: 1rem;
  }
}

.widget--filter {
  select {
    display: inline-block;
    width: auto;
  }
  input[type="text"] {
    display: inline-block;
    width: auto;
  }
  .widget_group {
    display: inline-block;
    > .widget {
      display: inline-block;
    }
  }
}