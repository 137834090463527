
/* Corporate Colors */
$baseGreen: #63b32b;//#6ab023;
$baseGrey: #4a4a49;

/* Corporate Grey Colors */
$grey65: #898989;
$grey45: #adadad;
$grey25: #d2d2d1;

/* Corporate Custom Colors */
$greenDark: #427016;
$greenDark: #428426;
$greenLight: #9ecc70;
$greenLight: #84d54f;

/* Various Colors */
$white: #ffffff;
$red: #9F3311;
$orange: #C27C27;

$baseFontColor: $baseGrey;