.pagination {
  @include clearfix;
  list-style: none;
  padding: 0;
  margin: 0;
  float: right;
  li {
    height: 30px;
    min-width: 30px;
    border: 1px solid $grey25;
    float: left;
    text-align: center;
    line-height: 28px;
    &.disabled {
      color: $grey45;
    }
    &.active {

    }
    span {
      display: block;
      height: 28px;
      min-width: 28px;
      padding-left: 3px;
      padding-right: 3px;
    }
    a {
      display: block;
      height: 28px;
      min-width: 28px;
      padding-left: 3px;
      padding-right: 3px;
    }
  }
}
.pagination-wrapper {
  @include clearfix;
}