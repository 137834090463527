


.navigation-toggler {
  display: none;
}
.navigation-toggler_label {
  display: none;
}

.navigation {

  &.navigation--main {

  }
}
.navigation_list {
  margin: 0;
  padding: 0;
  list-style: none;
  .navigation--main & {
    @include clearfix;
  }
  .navigation--secondary & {
    @include clearfix;
    background: $grey25;
  }
  .navigation--buttons & {
    @include clearfix;
    float: right;
  }
  .navigation_item {
    .navigation--main & {
      display: block;
      float: left;
      a {
        line-height: 50px;
        padding-left: 1rem;
        padding-right: 1rem;
        display: block;
        text-decoration: none;
        transition: background-color $defaultTransitionSettings;
        &:hover,
        &:focus {
          background: $grey25;
        }
        &:active {
          transform: none;
        }
        &.trail,
        &.active {
          color: $white;
          background: $baseGreen;
        }
      }
      strong {
        font-weight: normal;
        line-height: 50px;
        padding-left: 1rem;
        padding-right: 1rem;
        display: block;
        color: $white;
        background: $baseGreen;
      }
    }
    .navigation--secondary & {
      display: block;
      float: left;
      a {
        line-height: 40px;
        padding-left: 1rem;
        padding-right: 1rem;
        display: block;
        text-decoration: none;
        transition: background-color $defaultTransitionSettings;
        color: $baseFontColor;
        &:hover,
        &:focus {
          background: $grey65;
          color: $white;
        }
        &:active {
          transform: none;
        }
      }
      &.navigation_item--active {
        a {
          background: $grey45;
          &:hover,
          &:focus {
            background: $grey65;
            color: $white;
          }
        }
      }
      strong {
        font-weight: normal;
        line-height: 40px;
        padding-left: 1rem;
        padding-right: 1rem;
        display: block;
        color: $baseFontColor;
        background: $grey45;
      }
    }
    .navigation--buttons & {
      display: block;
      float: left;
      .btn {
        margin-left: 5px;
        margin-right: 5px;
        padding-left: 1rem;
        padding-right: 1rem;
      }
      &:first-of-type {
        .btn {
          margin-left: 0;
        }
      }
      &:last-of-type {
        .btn {
          margin-right: 0;
        }
      }
    }
  }
}
