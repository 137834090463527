.segmented {
  //width: 30%;
  margin-bottom: rem-calc(20);
  @include gridContainer;
  @include clearfix;
  .segmented_entry {
    padding-top: rem-calc(3);
    padding-bottom: rem-calc(3);
    @include gridSpan;
    float: left;
    width: 25%;
    &:last-of-type {
      border-bottom: 0;
    }
    &:nth-of-type(2n) {
      //background: lighten($grey25, 15);
    }
    span {
      display: block;
      //float: left;
      //width: 50%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      //padding-left: rem-calc(10);
      //padding-right: rem-calc(10);
      &.label {
        font-size: rem-calc(10);
        color: $grey65;
      }
      &.value {
        //text-align: right;
        padding-left: rem-calc(5);
      }
    }
  }
}

.segmented--links {
  text-align: right;
}
.segmented--content {

}