
%baseInput {
  /*display: block;
  width: 100%;
  padding: .5rem .75rem;
  font-size: 1rem;
  font-family: inherit;
  line-height: 1.25;
  color: $baseFontColor;
  background-color: $white;
  background-image: none;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid $grey45;
  border-radius: .25rem;
  outline: 0;
  transition: border-color $defaultTransitionSettings;*/
  display: block;
  //-webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  padding: 0.5rem;
  border: 1px solid $grey45;
  margin: 0;//0 0 1rem;
  font-family: inherit;
  font-size: 1rem;
  color: $baseFontColor;
  background-color: $white;
  //-webkit-box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  //box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  border-radius: .25rem;
  //-webkit-transition: -webkit-box-shadow 0.5s, border-color 0.25s ease-in-out;
  //transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  transition: border-color $defaultTransitionSettings;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  &.has-error {
    border-color: $red;
  }
  &:focus {
    border-color: $baseGreen;
  }
}

input[type="text"] {
  @extend %baseInput;
}

input[type="email"] {
  @extend %baseInput;
}

input[type="password"] {
  @extend %baseInput;
}

input[type="search"] {
  @extend %baseInput;
}

input[type="date"] {
  @extend %baseInput;
}

input[type="file"] {
  @extend %baseInput;
  border: 0;
  padding-left: 0;
}

input[type="checkbox"] {
  outline: 0;
}

input[type="radio"] {
  outline: 0;
}

textarea {
  @extend %baseInput;
  height: 200px;
  resize: vertical;
}

select {
  height: 2.4375rem;
  padding: 0.5rem;
  border: 1px solid $grey45;
  margin: 0;//0 0 1rem;
  font-size: 1rem;
  font-family: inherit;
  line-height: normal;
  color: $baseFontColor;
  background-color: $white;
  -webkit-appearance: none;
  -moz-appearance: none;
  //background-image: url(data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.…nts='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>);
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28137, 137, 137%29'></polygon></svg>");
  -webkit-background-size: 9px 6px;
  background-size: 9px 6px;
  background-position: right -1rem center;
  -webkit-background-origin: content-box;
  background-origin: content-box;
  background-repeat: no-repeat;
  padding-right: 1.5rem;
  width: 100%;
  outline: 0;
  border-radius: .25rem;
  transition: border-color $defaultTransitionSettings;
  &.has-error {
    border-color: $red;
  }
  &:focus {
    border-color: $baseGreen;
  }
}

label {
  display: inline-block;
  padding-bottom: 0.25rem;
  //font-weight: 300;
  &.required {
    font-weight: 700;
  }
}

.widget {
  margin-bottom: 1rem;
  @include clearfix;
  &.widget--invisible {
    display: none;
  }
  &.widget--headline {
    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 0;
    }
  }
}

/*.widget--filter {
  .input[type="text"] {
    width: auto;
  }
}*/