
%baseButton {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: top;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background: $grey65;
  color: $white;
  padding: rem-calc(10px) rem-calc(40px);
  position: relative;
  text-decoration: none;
  overflow: hidden;
  text-indent: 0;
  border: 0;
  border-radius: 2px;
  transition: background-color $defaultTransitionSettings;
  outline: 0;
  &.btn--small {
    padding: rem-calc(5px) rem-calc(20px);
  }
  &:hover,
  &:focus {
    background-color: $baseGrey;
    text-decoration: none;
    color: $white;
  }
  &:active {
    transform: translateY(0.2rem);
    color: $white;
  }
}

.btn {
  @extend %baseButton;
  &.btn--primary {
    background: $baseGreen;
    &:hover,
    &:focus {
      background-color: $greenDark;
    }
  }
  &.btn--secondary {
    background: $grey45;
    &:hover,
    &:focus {
      background-color: $grey65;
    }
  }
  &.btn--danger {
    background: $red;
    &:hover,
    &:focus {
      background-color: darken($red, 8%);
    }
  }
  &.btn--right {
    float: right;
  }
}

