

header {
  @include clearfix;
  position: relative;
  min-height: 100px;
  .container {
    position: relative;
  }
  .navigation--main {
    margin-bottom: 1rem;
    padding-top: 50px;
  }
  .navigation--secondary {
    margin-bottom: 1rem;
  }
  .logo {
    position: absolute;
    right: 0;
    top: 0;
  }
}