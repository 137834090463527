table {
  width: 100%;
  tr {

  }
  th {
    text-align: left;
    padding: .5rem;
  }
  td {
    text-align: left;
    padding: .5rem;
  }
}

thead {
  tr {
    background: $greenLight;
  }
  th {

  }
}
tfoot {
  tr {

  }
  td {
    font-weight: bold;
  }
}
tbody {
  tr {
    td {
      border-right: 1px solid lighten($grey25, 15);
      &:last-of-type {
        border-right: 0;
      }
    }
    &:nth-child(2n) {
      background: lighten($grey25, 15);
      td {
        border-right-color: $white;
      }
    }
  }
  td {

    &.col--actions {
      text-align: right;
      > .split-btn {
        float: right;
      }
    }
  }
}
.table-wrapper {
  //overflow: hidden;
  //overflow-x: auto;
  margin-top: 30px;
}

.table-filter {
  margin-top: 30px;
  + .table-wrapper {
    margin-top: 0;
  }
}

.dataTables_info {
  padding: .5rem;
  font-size: 80%;
  border-top: 1px solid $grey45;
  color: $grey65;
}

table {
  th {
    position: relative;
    border-right: 1px solid $white;
    &:last-of-type {
      border-right: 0;
    }
    &%baseSorting {
      font-family: 'Glyphicons Regular';
      position: absolute;
      display: inline-block;
      right: .5rem;
      top: 0.5rem;
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      vertical-align: top;
      speak: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    a {
      color: $baseFontColor;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
    &.sorting {
      padding-right: rem-calc(25);
      &:after {
        @extend %baseSorting;
        content: "\E404";
        color: $greenDark;
      }
    }
    &.sorting_asc {
      padding-right: rem-calc(25);
      &:after {
        @extend %baseSorting;
        content: "\E409";
      }
    }
    &.sorting_desc {
      padding-right: rem-calc(25);
      &:after {
        @extend %baseSorting;
        content: "\E410";
      }
    }
  }
}

.module--new {
  position: absolute;
  right: 0;
  margin-top: -60px;
}

.table-header {
  @include clearfix;
  .table-info {
    float: left;
    padding-top: 20px;
    color: $grey65;
  }
  .table-actions {
    float: right;
  }
  + .table-wrapper {
    margin-top: 10px;
  }
}
.table-actions {
  text-align: right;
}
