// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group functions
////

$font-size-root: 100% !default;

// scss-lint:disable ZeroUnit

/// Removes the unit (e.g. px, em, rem) from a value, returning the number only.
///
/// @param {Number} $num - Number to strip unit from.
///
/// @returns {Number} The same number, sans unit.
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

/// Converts one or more pixel values into matching rem values.
///
/// @param {Number|List} $values - One or more values to convert. Be sure to separate them with spaces and not commas. If you need to convert a comma-separated list, wrap the list in parentheses.
/// @param {Number} $base [null] - The base value to use when calculating the `rem`. If you're using Foundation out of the box, this is 16px. If this parameter is `null`, the function will reference the `$base-font-size` variable as the base.
///
/// @returns {List} A list of converted values.
@function rem-calc($values, $base: null) {
  $rem-values: ();
  $count: length($values);

  // If no base is defined, defer to the global font size
  @if $base == null {
    $base: $font-size-root;
  }

  // If the base font size is a %, then multiply it by 16px
  // This is because 100% font size = 16px in most all browsers
  @if unit($base) == '%' {
    $base: ($base / 100%) * 16px;
  }

  @if $count == 1 {
    @return -zf-to-rem($values, $base);
  }

  @for $i from 1 through $count {
    $rem-values: append($rem-values, -zf-to-rem(nth($values, $i), $base));
  }

  @return $rem-values;
}

/// Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the rem value is taken from the `$global-font-size` variable.
/// @access private
///
/// @param {Number} $value - Pixel value to convert.
/// @param {Number} $base [null] - Base for pixel conversion.
///
/// @returns {Number} A number in rems, calculated based on the given value and the base pixel value. rem values are passed through as is.
@function -zf-to-rem($value, $base: null) {
  // Calculate rem if units for $value is not rem
  @if (unit($value) != 'rem') {
    $value: strip-unit($value) / strip-unit($base) * 1rem;
  }
  // Turn 0rem into 0
  @if ($value == 0rem) { $value: 0; }
  @return $value;
}