.container {
  width: 95%;
  //max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  //position: relative;
}

.module--header {
  margin-bottom: 1rem;
  @include clearfix;
  .module--new {
    float: left;
    width: 75%;
    a {

    }
  }
  .module--livefilter {
    float: left;
    width: 25%;
  }
}