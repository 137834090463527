footer {
  @include clearfix;
  border-top: 1px solid $grey65;
  padding-top: 2rem;
  padding-bottom: 1rem;
  .footer_left {
    float: left;
    text-align: left;
  }
  .footer_right {
    float: right;
    text-align: right;
  }
  small {
    color: $grey65;
  }
}