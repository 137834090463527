

.alert {
  padding: 1rem;
  @include add-default-margin();
  //margin-left: .5rem;
  //margin-right: .5rem;
  color: $baseGrey;
  &:not([class*="alert--"]) {
    box-shadow:inset 0px 0px 0px 5px $grey25;
  }
  &[data-closable] {
    opacity: 1;
    transition: opacity $defaultTransitionSettings;
    &.js-closed {
      opacity: 0;
    }
    &.js-hidden {
      display: none;
    }
  }
}

.alert--success {
  // green
  background-color: $baseGreen;
  color: $white;
}
.alert--info {
  // blue
  background-color: $grey25;
  color: $baseGrey;
}
.alert--warning {
  // orange/yellow
  background-color: $orange;
  color: $white;
}
.alert--danger {
  // red
  background-color: $red;
  color: $white;
}
