body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.page-wrapper {
  flex: 1;
  padding-bottom: 3rem;
  padding-top: 1rem;
  > .login-form,
  > .container > .login-form {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}