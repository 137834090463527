


body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.3;
  color: $baseFontColor;
}

a {
  color: $baseGreen;
  text-decoration: none;
  transition: color $defaultTransitionSettings;
  display: inline-block;
  outline: none;
  &:hover {
    text-decoration: underline;
    color: $greenDark;
  }
  &:focus {
    text-decoration: underline;
    color: $greenDark;
  }
  &:active {
    transform: translateY(0.2rem);
  }
  &.link--danger {
    color: $red;
  }
}

mark {
  background: $baseGreen;
  color: $white;
}

code, kbd, pre, samp {
  font-family: 'Roboto Mono', monospace, monospace;
}

::selection {
  background: $baseGreen;
  color: $white;
}


%baseHeadline {
  font-weight: 700;
  margin-top: 0;
  color: $baseFontColor;
  small {
    font-weight: 400;
    font-size: 0.8em;
    color: $grey65;
  }
}

h1,
.h1 {
  @extend %baseHeadline;
  color: $baseFontColor;
  font-size: rem-calc(36);
  margin-bottom: .7em;
}
h2,
.h2 {
  @extend %baseHeadline;
  font-size: rem-calc(28);
  margin-bottom: .7em;
}
h3,
.h3 {
  @extend %baseHeadline;
  color: $baseGreen;
  font-size: rem-calc(18);
  margin-bottom: 1rem;
  small {
    font-size: (16em/18);
    color: $greenLight;
  }
}
h4,
.h4 {
  @extend %baseHeadline;
  font-size: rem-calc(16);
  margin-bottom: 1em;
  small {
    font-size: 1em;
  }
}
h5,
.h5 {
  @extend %baseHeadline;
  font-size: rem-calc(16);
  margin-bottom: 1em;
  small {
    font-size: 1em;
  }
}
h6,
.h6 {
  @extend %baseHeadline;
  font-size: rem-calc(16);
  margin-bottom: 1em;
  small {
    font-size: 1em;
  }
}

.invisible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}

img {
  max-width: 100%;
  height: auto;
}

.mono {
  //font-family: 'Roboto Mono', monospace, monospace;
}

.value--negative {
  color: $red;
}

.bg--base-green {
  background-color: $baseGreen;
}
.bg--base-grey {
  background-color: $baseGrey;
}
.bg--grey-65 {
  background-color: $grey65;
}
.bg--grey-45 {
  background-color: $grey45;
}
.bg--grey-25 {
  background-color: $grey25;
}
.bg--green-dark {
  background-color: $greenDark;
}
.bg--green-light {
  background-color: $greenLight;
}

.bg--white {
  background-color: $white;
}
.bg--red {
  background-color: $red;
}
.bg--orange {
  background-color: $orange;
}


.grid--row {
  @include clearfix;
  width: 100%;
  margin-left: -10px;
  margin-right: -10px;
}
.grid--col {
  float: left;
  width: 50%;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
  overflow-x: auto;
}