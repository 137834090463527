
.form--data {
  width: 50%;
}


%baseGridForm {
  &.model-form {

    > .widget {

    }
  }
}

%baseForm60 {
  width: 60%;
}


%baseWidget25 {
  width: 25%;
  float: left;
}
%baseWidget50 {
  width: 50%;
  float: left;
}
%baseWidget75 {
  width: 75%;
  float: left;
}

.model-form--addresses {
  @include gridContainer;
  .widget {
    @include gridSpan;
    clear: left;
  }
  .widget--name {
    @extend %baseWidget50;
  }
  .widget--vorname {
    @extend %baseWidget50;
    clear: none;
  }
  .widget--strasse {
    @extend %baseWidget75;
  }
  .widget--hausnummer {
    @extend %baseWidget25;
    clear: none;
  }
  .widget--plz {
    @extend %baseWidget25;
  }
  .widget--ort {
    @extend %baseWidget75;
    clear: none;
  }
  .widget--telefon {
    @extend %baseWidget50;
  }
  .widget--fax {
    @extend %baseWidget50;
    clear: none;
  }
}

.model-form--delivery {
  @include gridContainer;
  .widget {
    @include gridSpan;
    clear: left;
  }
  .widget--abgabevon {
    @extend %baseWidget50;
    clear: none;
  }
  .widget--abgabebis {
    @extend %baseWidget50;
    clear: none;
  }
  .widget--newaddress_hit {

  }
  .widget--newaddress_company {

  }
  .widget--newaddress_street {
    @extend %baseWidget75;
    clear: none;
  }
  .widget--newaddress_streetno {
    @extend %baseWidget25;
    clear: none;
  }
  .widget--newaddress_plz {
    @extend %baseWidget25;
    clear: none;
  }
  .widget--newaddress_city {
    @extend %baseWidget75;
    clear: none;
  }
  .widget--lieferung_n {
    @extend %baseWidget25;
    clear: none;
  }
  .widget--lieferung_nh4n {
    @extend %baseWidget25;
    clear: none;
  }
  .widget--lieferung_p2o5 {
    @extend %baseWidget25;
    clear: none;
  }
  .widget--lieferung_k2o {
    @extend %baseWidget25;
    clear: none;
  }
  .widget--newuntersuchung_angabevon {
    @extend %baseWidget25;
    clear: none;
  }
  .widget--newuntersuchung_n {
    @extend %baseWidget25;
  }
  .widget--newuntersuchung_p2o5 {
    @extend %baseWidget25;
    clear: none;
  }
  .widget--newuntersuchung_k2o {
    @extend %baseWidget25;
    clear: none;
  }
  .widget--newuntersuchung_nh4n {
    @extend %baseWidget25;
    clear: none;
  }
  .widget--newuntersuchung_ts {
    @extend %baseWidget25;
    clear: none;
  }
  .widget--newuntersuchung_ntierisch {
    @extend %baseWidget25;
    clear: none;
  }
}

.model-form--holdingwj {
  @include gridContainer;
  @extend %baseForm60;
  .widget {
    @include gridSpan;
    clear: left;
  }
  .widget--gesamtn {
    @extend %baseWidget50;
  }
  .widget--gesamtp2o5 {
    @extend %baseWidget50;
    clear: none;
  }
  .widget--ueberhangn {
    @extend %baseWidget50;
  }
  .widget--ueberhangp2o5 {
    @extend %baseWidget50;
    clear: none;
  }
}

.model-form--holding {
  @include gridContainer;
  @extend %baseForm60;
  .widget {
    @include gridSpan;
    clear: left;
  }
  .widget--hitzidnr {
    @extend %baseWidget50;
  }
  .widget--betriebsnummer {
    @extend %baseWidget50;
    clear: none;
  }
  .widget--name {
    @extend %baseWidget50;
  }
  .widget--firstname {
    @extend %baseWidget50;
    clear: none;
  }
  .widget--strasse {
    @extend %baseWidget75;
  }
  .widget--hausnummer {
    @extend %baseWidget25;
    clear: none;
  }
  .widget--plz {
    @extend %baseWidget25;
  }
  .widget--ort {
    @extend %baseWidget75;
    clear: none;
  }
  .widget--telefon {
    @extend %baseWidget50;
  }
  .widget--fax {
    @extend %baseWidget50;
    clear: none;
  }
  .widget--vermittlungsgarantiegesamtn {
    @extend %baseWidget50;
  }
  .widget--vermittlungsgarantiep2o5 {
    @extend %baseWidget50;
    clear: none;
  }
  .widget--vermittlungsgarantiebeginn {
    @extend %baseWidget50;
  }
  .widget--vermittlungsgarantieende {
    @extend %baseWidget50;
    clear: none;
  }
  .widget--longitude {
    @extend %baseWidget50;
  }
  .widget--latitude {
    @extend %baseWidget50;
    clear: none;
  }
}

.model-form--analyse {
  @include gridContainer;
  .widget {
    @include gridSpan;
    clear: left;
    &[class*="widget--datenfelder_wert__"] {
      @extend %baseWidget25;
      clear: none;
    }
  }
}

.model-form--addresses {
  @extend %baseForm60;
}
.model-form--fertilizertypes {
  //@extend %baseForm60;
  @include gridContainer;
  .widget {
    @include gridSpan;
    clear: left;
  }
  .widget--ktxt {
    @extend %baseWidget75;
    clear: none;
  }
  .widget--dictdungartgueltigkeit_gueltigkeitbeginn {
    @extend %baseWidget25;
    clear: none;
  }
  [class*="widget--datenfelder_wert__"] {
    @extend %baseWidget25;
    clear: none;
  }
  .widget--datenfelder_wert__13 {
    @extend %baseWidget50;
    clear: both;
  }
}

.filter-form {
  @include clearfix;
  .widget {
    float: left;
    margin-right: 20px;
    &.widget--button {
      margin-right: 0;
    }
  }
}